import React from "react"
import { Link } from "gatsby"
import "@fontsource/newsreader"

export default function Home() {
  return (
    <div class="container">
      <div class="column">
        <h1>papers</h1>
        <p>
          {" "}
          Google scholar{" "}
          <a href="https://scholar.google.com/citations?hl=en&user=lAYFmLMAAAAJ">
            here
          </a>
          .
        </p>
        <ul class="paperTitles text-normal">
          <h4>2021 MIT, Columbia, & NASA GISS COVID-19 Climate Coalition</h4>
          <p>Advisors: Dr. Cynthia Rosenzweig and Dr. Janelle Knox-Hayes</p>
          <p>paper(s) being written</p>
          <li>
            <p>
              Funding allocation and co-authorship on NASA ROSES (Research
              Opportunities in Space Science) Science Mission Directorate
              solicitation — pending.
            </p>
          </li>
          {/* <li><p>Work presented to NYC DOH (Department of Health) and NYC DOT (Department of Transportation).</p></li> */}
          <h4>2020 NASA Goddard Institute for Space Studies</h4>
          <p>Advisors: Dr. Reginald Blake and Dr. Hamid Norouzi</p>
          <li>
            <p>
              <b>Bilik, M.</b>, Teyton, A., Woody, M., Piacentini, L., Norouzi,
              H. and Blake, R. (2020, October). Implementing Q Learning and
              Multimethod Data Analysis to Determine Land Surface Temperature
              and Heat Indexed Routes at the Neighborhood-Scale. Poster
              presented at the American Geophysical Union Fall 2020 Conference,
              San Francisco, CA. Abstract retrieved from
              https://agu.confex.com/agu/fm20/meetingapp.cgi/Paper/754127
            </p>
          </li>
          <li>
            <p>
              Teyton, A., Woody, M., Piacentini, L., <b>Bilik, M.,</b> Norouzi,
              H. and Blake, R. (2020, October). The Interconnection between the
              Urban Heat Island and Social Factors on Health Outcomes:
              Transforming Heat Wave Experiences into Meaningful Community
              Improvement. Poster presented at the American Geophysical Union
              Fall 2020 Conference, San Francisco, CA. Abstract retrieved from
              https://agu.confex.com/agu/fm20/meetingapp.cgi/Paper/752093
            </p>
          </li>
          <li>
            <p>
              Piacentini, L., Woody, M., Teyton, A., <b>Bilik, M.,</b> Norouzi,
              H. and Blake, R. (2020, October). The Urban Heat Island Effect:
              Capturing Community Engagement in Bedford-Stuyvesant, Brooklyn,
              NY. Poster presented at the American Geophysical Union Fall 2020
              Conference, San Francisco, CA. Abstract retrieved from
              https://agu.confex.com/agu/fm20/meetingapp.cgi/Paper/760446
            </p>
          </li>
          <li>
            <p>
              Woody, M., Teyton, A., Piacentini, L., <b>Bilik, M.,</b> Norouzi,
              H. and Blake, R. (2020, October). Transforming Students into
              Climate Scientists through Urban Heat Island Investigations.
              Poster presented at the American Geophysical Union Fall 2020
              Conference, San Francisco, CA. Abstract retrieved from
              https://agu.confex.com/agu/fm20/meetingapp.cgi/Paper/759197
            </p>
          </li>
          <h4>2019 NASA Goddard Institute for Space Studies</h4>
          <p>Advisors: Dr. Reginald Blake and Dr. Hamid Norouzi</p>
          <li>
            <p>
              Liebowitz, A., Sebastian, E., Yanos, C., <b>Bilik, M.</b>,
              Ginchereau, J., Valentine, M., Barclay, K., Montoya, S., Rice, M.,
              Norouzi, H. and Blake, R. (2020, July). Urban Heat Islands and
              Remote Sensing: Characterizing Land Surface Temperature on the
              Neighborhood Scale. Paper presented at the IEEE International
              Geoscience and Remote Sensing Symposium, Waikoloa, HI.
            </p>
          </li>
          <li>
            <p>
              Liebowitz, A., Sebastian, E., Yanos, C., <b>Bilik, M.</b>,
              Ginchereau, J., Valentine, M., Barclay, K., Montoya, S., Rice, M.,
              Norouzi, H. and Blake, R. (2019, December). Engaging Citizen
              Scientists in Characterizing Urban Heat Island at the Neighborhood
              Scale Using Satellite and Ground Observations. Poster presented at
              the American Geophysical Union Centennial Conference, San
              Francisco, CA. Abstract retrieved from
              https://agu.confex.com/agu/fm19/meetingapp.cgi/Paper/592972
            </p>
          </li>
          {/* <h4>2019 NYC Mayor's Office of Data Analytics</h4>
          <li>
            <p>
              <b>Bilik, M.</b>, Lu, B., (2019, June). Civic Engagement & Data
              Literacy: A Novel Machine Learning Solution. Presented at New York
              City Hall, NYC Open Data Youth Leadership Council.
            </p>
          </li> */}
        </ul>
        <Link to="/">back</Link>
      </div>
    </div>
  )
}
